import React from 'react'
import styled from 'styled-components'
import info_svg from '../../assets/info_outline-24px.svg'

const TooltipIcon = styled('img')`
  width: 18px;
  height: 18px;
  margin-left: 1rem;
`

const TooltipText = styled('span')`
  visibility: hidden;
  width: 250px;
  background-color: ${({ theme }) => theme.color.brownLight};
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
`

const StyledTooltip = styled('div')`
  position: relative;
  display: inline-block;

  &:hover {
    cursor: pointer;
    span {
      visibility: visible
    }
  }
`

const Tooltip = ({ title }) =>  (
  <StyledTooltip>
    <TooltipIcon src={info_svg} alt="" />
    <TooltipText>{title}</TooltipText>
  </StyledTooltip>
)

export default Tooltip
