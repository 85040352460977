import styled from 'styled-components'

const Hr = styled.hr`
    height: 1px;
    border-top: 1px solid ${({ theme }) => theme.color.purpleLight};
    border-width: 1px 0 0 0;
    border-style: solid;
    margin: 1.5rem 0;
`

export default Hr
