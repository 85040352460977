// Device sizes based on Bootstrap (see https://getbootstrap.com/docs/4.0/layout/grid/#variables)
export const DEVICE_XS = 0;
export const DEVICE_SM = 576;
export const DEVICE_MD = 768;
export const DEVICE_LG = 992;
export const DEVICE_XL = 1200;

export const DEVICE_XS_UP = `(min-width: ${DEVICE_XS}px)`;
export const DEVICE_SM_UP = `(min-width: ${DEVICE_SM}px)`;
export const DEVICE_SM_DOWN = `(max-width: ${DEVICE_SM}px)`;
export const DEVICE_MD_UP = `(min-width: ${DEVICE_MD}px)`;
export const DEVICE_LG_UP = `(min-width: ${DEVICE_LG}px)`;
export const DEVICE_LG_DOWN = `(max-width: ${DEVICE_LG}px)`;
export const DEVICE_XL_UP = `(min-width: ${DEVICE_XL}px)`;

export const isDeviceLgUp = (): boolean => typeof window !== 'undefined' && window.innerWidth >= DEVICE_LG;
export const isMobilePortrait = (): boolean => window.innerWidth <= (DEVICE_SM - 1);
export const isMobileLandscape = (): boolean => window.innerWidth >= DEVICE_SM && window.innerWidth <= (DEVICE_MD - 1);
export const isTabletPortrait = (): boolean => window.innerWidth >= DEVICE_MD && window.innerWidth <= (DEVICE_LG - 1);
export const isTabletLandscape = (): boolean => window.innerWidth >= DEVICE_LG && window.innerWidth <= (DEVICE_XL - 1);
export const isDesktop = (): boolean => window.innerWidth >= DEVICE_XL;
