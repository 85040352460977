import React, { useEffect } from 'react'

export const apiUrl = process.env.REACT_APP_API_URL


const getQueryStringValue = (search, key) => {
  return decodeURIComponent(search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'))
}

const Download = ({ location: { pathname, search } }) => {
  useEffect(() => {
    const encryptedString = getQueryStringValue(search, 'data')

    if (encryptedString) {
      window.location = `${apiUrl}/orders/download${search}`
    }
  }, [search])

  return (
    <div>
      De download van uw {pathname.includes('controle') ? 'controlerapport' : 'scanrapport'} start automatisch. Een ogenblik geduld alstublieft.
    </div>
  )
}

export default Download
