import React, { Component } from 'react'
import { withResizeDetector } from 'react-resize-detector'

const postMessage = (height) => {
  if (window.location !== window.parent.location && height) {
    window.parent.postMessage(height + 30, '*')
  }
}

class ResizeListener extends Component {
  componentDidUpdate(prevProps) {
    const { height } = this.props

    if (height !== prevProps.height) {
      postMessage(height)
    }
  }

  render() {
    const { children, targetRef } = this.props
    return <div ref={targetRef}>{children}</div>
  }
}

export default withResizeDetector(ResizeListener)
