import React from 'react'
import styled from 'styled-components'
import Hr from '../components/layout/Hr'
import H1 from '../components/typography/H1'

const Page = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  margin-top: 2rem;
`

const Done = () => (
  <Page>
    <Container>
      <H1>Jouw koopcontract wordt op dit moment gescand.</H1>
      <Hr />
      <p>
        Je ontvangt binnen enkele minuten een e-mail met het resultaat.
        Let op: deze kan ook in de "spam" map terecht komen.
      </p>
    </Container>
  </Page>
)

export default Done
