import React from 'react'
import styled from 'styled-components'

interface LabelProps {
  children: any
  required?: any
}

const Container = styled('label')`
  display: inline-block;
  color: ${({ theme }) => theme.color.purpleDark};
  font-size: 16px;
  margin-bottom: .8rem;
`

const Label: React.FC<LabelProps>  = ({ children, required, ...restProps }) => (
  <Container {...restProps}>{children}{required && '*'}</Container>
)

export default Label
