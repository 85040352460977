import { ErrorMessage } from 'formik'
import React from 'react'
import styled from 'styled-components'
import FormError from './FormError'
import Label from './Label'

const StyledSelect = styled.select`
  display: block;
  border: 1px solid ${({ error }) => error ? '#ED1C24' : '#B7AEAE'};
  border-radius: 3px;
  padding: 1rem 1.3rem;
  cursor: pointer;

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.color.purpleRegular}`};
  }
`

const SelectField = ({
  label,
  field,
  form,
  handleBlur,
  options,
  form: {
    errors,
    touched,
  },
  ...restProps
}) => (
  <>
    <Label htmlFor={field.name}>{label}</Label>
    <StyledSelect
      name={field.name}
      value={field.value}
      onChange={(e) => form.setFieldValue(field.name, e.target.value)}
      onBlur={handleBlur}
      error={errors[field.name] && touched[field.name]}
      {...restProps}
    >
      <option value="" disabled>
        Maak een keuze
      </option>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </StyledSelect>
    <ErrorMessage component={FormError} name={field.name} />
  </>
)

export default SelectField
