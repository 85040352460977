import React from 'react'
import { Field } from 'formik'
import { Col } from 'styled-bootstrap-grid'
import FormGroup from '../components/layout/FormGroup'
import H1 from '../components/typography/H1'
import Hr from '../components/layout/Hr'
import UploadField from '../components/form/UploadField'

const UploadContract = ({ children }) => (
  <>
    <H1>Upload jouw koopcontract</H1>
    <Hr />
    <p>Upload hier je koopcontract als PDF-bestand. Let op: je kunt geen bijlages toevoegen. Het gaat alleen om de koopovereenkomst.</p>
    <FormGroup row>
      <Col lg="6">
        <Field name="file" component={UploadField} label="Selecteer jouw koopovereenkomst" />
      </Col>
    </FormGroup>
    {children}
  </>
)

export default UploadContract
