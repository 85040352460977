import styled from 'styled-components'
import { DEVICE_SM_DOWN } from '../../utils/deviceUtils'

const ButtonSecondary = styled.button`
  height: 39px;
  background: white;
  box-shadow: 0px 3px 6px #00000034;
  border: 1px solid ${({ theme }) => theme.color.orangeDark};
  opacity: 1;
  color: ${({ theme }) => theme.color.orangeDark};
  border-radius: 2px;
  font-family: ${({ theme }) => theme.font.default};
  padding: 0 3rem;
  min-width: 140px;

  &:hover {
    box-shadow: 0px 3px 9px #00000066;
  }

  &:disabled {
    background: #FF8201;
    opacity: 0.5;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }

  @media ${DEVICE_SM_DOWN} {
    padding: 0 1rem;
    min-width: 100px;
  }
`

export default ButtonSecondary
