import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import FormError from './FormError'
import { ErrorMessage } from 'formik'
import upload_svg from '../../assets/get_app-24px.svg'
import delete_svg from '../../assets/delete-24px.svg'
import Label from './Label'

const UploadButton = styled.div`
  border: 1px solid #ff8201;
  border-radius: 3px;
  background: white;
  padding: 1rem;
  color: #ff8201;
  max-width: 200px;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;

  &:hover {
    box-shadow: 0px 3px 9px #00000066;
  }

  &:active {
    border: 1px solid #ff8201;
  }
`

const RemoveButton = styled.div`
  display: flex;
  justify-content: space-between;
  background: #e7e7ef;
  border: 1px solid #9a90b8;
  border-radius: 3px;
  padding: 1rem;
  color: #000000;
`

const FileText = styled.div`
  overflow: hidden;
`

const RemoveIcon = styled.div`
  cursor: pointer;
`

const UploadField = ({ label, field, form: { values, setFieldValue, handleChange } }) => {
  const [disabled, setDisabled] = useState(false)
  const [buttonText, setButtonText] = useState('Upload Contract')
  const contractUpload = useRef(null)
  const onButtonClick = () => {
    contractUpload.current.click()
  }

  useEffect(() => {
    if (values[field.name]) {
      setDisabled(true)
      setButtonText(values[`${field.name}name`])
    }
  }, [values, field.name])

  const handleRemove = (event) => {
    setDisabled(false)
    setFieldValue(field.name, '')
    setFieldValue('contract', '')
    setFieldValue('filename', '')
    setFieldValue('contractValid', '')
    handleChange(event)
  }

  return (
    <>
      {label && <Label htmlFor={field && field.name}>{label}</Label>}
      {!disabled ? (
        <UploadButton id="upload-btn" onClick={onButtonClick}>
          <img src={upload_svg} alt="" /> Upload PDF
          <input
            ref={contractUpload}
            name={field.name}
            type="file"
            onChange={(event) => {
              const target = event.currentTarget
              setFieldValue('contract', target && target.files[0])
              setFieldValue('filename', target && target.files[0].name)
              handleChange(event)
              setButtonText(target.files[0] ? target.files[0].name : buttonText)
              setDisabled(true)
            }}
            style={{ display: 'none' }}
          />
        </UploadButton>
      ) : (
        <RemoveButton type="button">
          <FileText>{buttonText}</FileText>
          <RemoveIcon onClick={handleRemove}>
            <img src={delete_svg} alt="" />
          </RemoveIcon>
        </RemoveButton>
      )}
      <ErrorMessage component={FormError} name="contract" />
    </>
  )
}

export default UploadField
