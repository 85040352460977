import styled from 'styled-components'
import { DEVICE_SM_DOWN } from '../../utils/deviceUtils'

const Button = styled.button`
  height: 39px;
  background: #FF8201;
  box-shadow: 0px 3px 6px #00000034;
  opacity: 1;
  color: #FFFFFF;
  border-radius: 2px;
  font-family: ${(props) => props.theme.font.default};
  padding: 0 3rem;
  min-width: 140px;

  &:hover {
    background: #FF8201;
    box-shadow: 0px 3px 9px #00000066;
  }

  &:active {
    opacity: 0.7;
  }

  &:disabled {
    background: #FF8201;
    opacity: 0.5;
  }

  &:disabled:hover {
    cursor: not-allowed;
  }

  @media ${DEVICE_SM_DOWN} {
    padding: 0 1rem;
    min-width: 100px;
  }
`

export default Button
