import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import RootProvider from './components/providers/RootProvider'
import FreeOrder from './pages/FreeOrder'
import PaidOrder from './pages/PaidOrder'
import Download from './pages/Download'

const Container = styled.div`
  width: 100%;
  padding: ${() => (window.location !== window.parent.location) ? '0 0 20px 0': '20px'};
  margin: 0;
`

const App = () => {
  return (
    <RootProvider>
      <Container>
        <Switch>
          <Route path="/gratis" component={FreeOrder} />
          <Route path="/betaald" component={PaidOrder} />
          <Route path="/download" component={Download} />
        </Switch>
      </Container>
    </RootProvider>
  )
}

export default App
