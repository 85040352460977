import React from 'react'
import * as yup from 'yup'
import { debounce } from 'lodash'
import Wizard from '../components/form/Wizard'
import SanityCheck from './SanityCheck'
import UploadContract from './UploadContract'
import ValidateContract from './ValidateContract'
import Overview from '../components/form/Overview'
import Done from './Done'
import Form from './Form'
import { createOrder } from '../api/orderApi'
import { createPayment } from '../api/paymentApi'
import { validateCoupon } from '../api/couponApi'

const emptyOrder = {
  type: 'PAID',
  modelContract: '',
  oldbuild: '',
  conditions: false,
  buyer_type: '',
  initials: '',
  prefix: '',
  name: '',
  phone_number: '',
  phone_mobile: '',
  email: '',
  zipcode: '',
  street: '',
  house_number: '',
  house_number_affix: '',
  gender: '',
  file: '',
  contract: '',
  filename: '',
  bank: '',
  coupon: '',
}
const locations = ['/betaald', '/betaald/form', '/betaald/upload', '/betaald/confirm', '/betaald/bedankt']

const barItems = [
  { idx: 1, name: 'Check' },
  { idx: 2, name: 'Gegevens' },
  { idx: 3, name: 'Upload' },
  { idx: 4, name: 'Bevestiging' },
]

const ASYNC_VALIDATION_TIMEOUT_IN_MS = 1000

const validationCouponDebounced = async (value, resolve) => {
  await validateCoupon(value)
    .then(() => resolve(true))
    .catch(() => resolve(false))
}

const validationDebounced = debounce(validationCouponDebounced, ASYNC_VALIDATION_TIMEOUT_IN_MS)

const orderValidationSchema = [
  yup.object().shape({
    modelContract: yup.bool().required('Maak toch een keuze').oneOf([true], 'customErrorMessage'),
    oldbuild: yup.bool().required('Maak toch een keuze').oneOf([true], 'customErrorMessage'),
    buyer_type: yup.string().required('Maak toch een keuze'),
  }),
  yup.object().shape(
    {
      initials: yup
        .string()
        .matches(/^([A-Z]\.)+$/, {
          message: 'De initialen moeten worden aangeleverd met puntjes en in hoofdletters. Bv. M.J.W.',
        })
        .required('Verplicht'),
      prefix: yup.string().matches(/^([a-z ])+/, {
        message: 'De tussenvoegsels moet in kleine letters zijn geschreven',
      }),
      name: yup
        .string()
        .matches(/^[A-Z][A-za-z ,.'-]+/, {
          message: 'De achternaam moet beginnen met een hoofdletter. Er mogen geen tussenvoegsels in dit veld staan',
        })
        .required('Verplicht'),
      phone_number: yup.string().matches(/^0[1-9][0-9]{8}$|^00[1-9][0-9]{7}$/, {
        message: 'Het telefoonnummer moet beginnen met een 0 en 10 cijfers bevatten',
      }),
      phone_mobile: yup.string().matches(/^0[1-9][0-9]{8}$|^00[1-9][0-9]{7}$/, {
        message: 'Het telefoonnummer moet beginnen met een 0 en 10 cijfers bevatten',
      }),
      email: yup.string().email().required('Verplicht'),
      zipcode: yup
        .string()
        .matches(/^\d{4} [A-Z]{2}/, {
          message: 'De postcode moet 4 cijfers, een spatie en 2 hoofdletters bevatten',
        })
        .required('Verplicht'),
      street: yup
        .string()
        .matches(/^[\u0020-\u00FF\u2019]{1,80}$/, {
          message: 'Voer een geldige straatnaam in',
        })
        .required('Verplicht'),
      house_number: yup
        .string()
        .matches(/^\d+$/, {
          message: 'Het huisnummer kan alleen cijfers bevatten, dus geen toevoeging',
        })
        .required('Verplicht'),
      house_number_affix: yup.string(),
      gender: yup.string().required('Verplicht'),
    },
    ['phone_number', 'phone_mobile']
  ),
  yup.object().shape({
    contract: yup
      .mixed()
      .required('Upload een bestand')
      .test('is-pdf-file', 'Upload een pdf-bestand', (value) => value && value.type === 'application/pdf'),
  }),
  yup.object().shape({
    conditions: yup.bool().required().oneOf([true], 'Ga akkoord met de voorwaarden'),
    coupon: yup
      .string()
      .trim()
      .test('is-coupon-valid', 'Ongeldige couponcode', (value) => {
        if (value && value.length >= 12) {
          return new Promise((resolve) => validationDebounced(value, resolve))
        }
        return true
      })
      .min(12, 'Ongeldige couponcode'),
    bank: yup.string().when('coupon', {
      is: (coupon) => !coupon,
      then: yup.string().required('Selecteer uw bank'),
      otherwise: yup.string().notRequired(),
    }),
  }),
]

const redirect = ({ data: { RedirectURL } }) =>
  window.location !== window.parent.location ? (window.parent.location = RedirectURL) : (window.location = RedirectURL)

const PaidOrder = () => (
  <Wizard
    locations={locations}
    barItems={barItems}
    initialValues={emptyOrder}
    validationSchema={orderValidationSchema}
    onSubmit={(values, actions, history) => {
      if (values.coupon) {
        createOrder(values)
        actions.setSubmitting(false)
        history.push('/betaald/bedankt')
      }
      if (values.payment_status) {
        createPayment(values).then((response) => {
          redirect(response)
        })
      }
      if (values.bank) {
        createOrder(values).then((response) => {
          createPayment(response.data).then((response) => {
            redirect(response)
          })
        })
      }
    }}>
    <SanityCheck pay={true} />
    <Form />
    <UploadContract>
      <ValidateContract />
    </UploadContract>
    <Overview pay={true} />
    <Done />
  </Wizard>
)

export default PaidOrder
