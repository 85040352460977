import { AxiosPromise } from 'axios'
import { get, post, apiUrl } from '.'

export const getBanks = (): AxiosPromise<any> => get({
  url: `${apiUrl}/payments/banks`,
})

export const createPayment = (order: any): AxiosPromise<any> => post({
  url: `${apiUrl}/payments/`,
  data: {'id': order.id}
})