import styled from 'styled-components/macro';

interface IFormGroupProps {
    row?: boolean,
}

const FormGroup = styled('div')`
  display: flex;
  flex-direction: ${(props: IFormGroupProps) => (props.row ? 'row' : 'column')};
  margin-right: ${(props: IFormGroupProps) => (props.row ? '-15px' : 0)};
  margin-left: ${(props: IFormGroupProps) => (props.row ? '-15px' : 0)};
  margin-bottom: 2rem;
  flex-wrap: wrap;
`

export default FormGroup
