import axios, { AxiosRequestConfig } from 'axios'

export const newCancellationToken = () => axios.CancelToken.source()
export const isCancellationError = (err: Error) => axios.isCancel(err)

export const apiUrl = process.env.REACT_APP_API_URL

const getHeaders = (config: AxiosRequestConfig) => ({
  'Content-Type': 'application/json',
  ...config.headers,
})

export const api = axios.create({
  // baseURL: process.env.RAZZLE_API_BASE_URL,
  // timeout: 5000
})

export const get = (config: AxiosRequestConfig) => api({
  method: 'GET',
  ...config,
  headers: getHeaders(config)
})

export const patch = (config: AxiosRequestConfig) => api({
  method: 'PATCH',
  ...config,
  headers: getHeaders(config)
})

export const put = (config: AxiosRequestConfig) => api({
  method: 'PUT',
  ...config,
  headers: getHeaders(config)
})

export const post = (config: AxiosRequestConfig) => api({
  method: 'POST',
  ...config,
  headers: getHeaders(config)
})

export const remove = (config: AxiosRequestConfig) => api({
  method: 'DELETE',
  ...config,
  headers: getHeaders(config)
})
