import React from 'react'
import { ThemeProvider } from 'styled-components'
import { BaseCSS } from 'styled-bootstrap-grid'
import { theme } from '../../theme'
import GlobalStyle from '../layout/GlobalStyle'
import Helmet from 'react-helmet'

const RootProvider = ({ children }) => (
  <>
    <Helmet titleTemplate="%s - Vereniging Eigen Huis">
      <html lang="nl" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <BaseCSS />
      <GlobalStyle />
      {children}
    </ThemeProvider>
  </>
)

export default RootProvider