import { DefaultTheme } from 'styled-components'

export const theme: DefaultTheme = {
  color: {
    brownRegular: '#B7AEAE',
    brownDark: '#594E4E',
    purpleDark: '#352171',
    redDark: '#ED1C24',
    orangeDark: '#FF8201',
    purpleRegular: '#9A90B8',
    orangeRegular: '#FFB55F',
    redRegular: '#F0444A',
    purpleLight: '#E1E1ED',
    orangeLight: '#FFF1E0',
    brownLight: '#F2F2F5',
    dark: '#292b2c',
    neutralDark: '#a48a76', // Dark Sand
    neutralMedium: '#a08b79',
    neutralLight: '#f6f1e6', // Sand
    primary: '#e84d0e', // Orange
    secondary: '#fcce4c', // Yellow
    // tertiary: '#fcce4c', 
    error: '#b31d2b',
    success: '#28a745',
    warning: '#ffc107'
  },
  font: {
    default: 'MuseoSans, -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif'
  },
  textColor: {
    default: '#292b2c',
    primary: '#292b2c',
    danger: '#ED1C24',
    muted: '#B7AEAE',
    faded: '#E0D6D6', 
  },
  margin: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
  },
  padding: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
  }
}
