import React from 'react'
import styled from 'styled-components'
import Label from './Label'
import Input from './Input'
import FormError from './FormError';
import { ErrorMessage } from 'formik';


const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin: .5rem 0;
  flex-direction: column;
  flex-wrap: wrap;
  
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;


const InputField = (props) => {
  const {
    label,
    field,
    form,
    placeholder,
    required,
    status,
    form: {
      errors,
      touched,
    },
    ...restProps
  } = props

  const handleChange = (e) => {
    form.setFieldValue(field.name, e.target.value)
  }

  return (
    <Container>
      <Label required={required}>{label}</Label>
      <Input value={field.value || ''} placeholder={placeholder} onChange={handleChange} error={errors[field.name] && touched[field.name]} status={status && status[field.name]} {...restProps} />
      <ErrorMessage component={FormError} name={field.name} />
    </Container>
  );
};

export default InputField
