import React from 'react'
import * as yup from 'yup'
import Wizard from '../components/form/Wizard'
import SanityCheck from './SanityCheck'
import UploadContract from './UploadContract'
import Overview from '../components/form/Overview'
import Done from './Done'
import { createOrder } from '../api/orderApi'

const emptyOrder = {
  type: 'FREE',
  modelContract: '',
  oldbuild: '',
  conditions: false,
  buyer_type: '',
  file: '',
  contract: '',
  filename: ''
}

const locations = ['/gratis', '/gratis/upload', '/gratis/confirm', '/gratis/bedankt']

const barItems = [
  { idx: 1, name: 'Check' },
  { idx: 2, name: 'Upload' },
  { idx: 3, name: 'Bevestiging' }, 
]

const orderValidationSchema = [
  yup.object().shape({
    modelContract: yup
      .bool()
      .required('Maak toch een keuze')
      .oneOf([true], 'customErrorMessage'),
    oldbuild: yup
      .bool()
      .required('Maak toch een keuze')
      .oneOf([true], 'customErrorMessage'),
    buyer_type: yup
      .string()
      .required('Maak toch een keuze'),
    email: yup
      .string()
      .email()
      .required()
    }),
  yup.object().shape({
    contract: yup
      .mixed()
      .required('Upload een bestand')
      .test('is-pdf-file', 'Upload een pdf-bestand', value => 
        value && value.type === 'application/pdf'
      )
    }),
  yup.object().shape({
    conditions: yup
      .bool()
      .required()
      .oneOf([true], 'Ga akkoord met de voorwaarden')
    })
]

const FreeOrder = () => (
  <Wizard
    locations={locations}
    barItems={barItems}
    initialValues={emptyOrder}
    validationSchema={orderValidationSchema}
    onSubmit={(values, actions) => {
      createOrder(values)
      actions.setSubmitting(false)
    }}>
    <SanityCheck pay={false} />
    <UploadContract />
    <Overview pay={false} />
    <Done />
  </Wizard>
)

export default FreeOrder
