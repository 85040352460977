import React from 'react'
import styled from 'styled-components'
import chevron_right from '../../assets/chevron_right-24px.svg'

const Link = styled.a`
  cursor: pointer;
  text-decoration: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: ${({ theme }) => theme.color.orangeDark};
  display: flex;
  align-items: center;
  font-size: '18px';
  font-family: ${({ theme }) => theme.font.default};
  font-weight: 700;
  transition: margin 200ms ease, color 200ms ease;
  width: fit-content;
  margin-top: 2rem;

  img {
    margin-right: 0.75rem;
    transition: margin 200ms ease;
  }

  &:hover {
    color: ${({ theme }) => theme.color.purpleDark};
    margin-left: 0.25rem;

    img {
      margin-right: 0.5rem;
    }
  }
`

const TextLink = ({ href, children, ...rest }) => (
  <Link href={href} {...rest}>
    <img src={chevron_right} alt="" />
    {children}
  </Link>
)

export default TextLink
