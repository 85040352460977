import React from 'react'
import styled from 'styled-components/macro'
import Label from './Label'
import FormError from './FormError'
import { ErrorMessage } from 'formik'
import Tooltip from './Tooltip'

const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin: .5rem 0;
  flex-direction: ${({ row }) => row ? 'row' : 'column'};
  flex-wrap: wrap;
  
  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

const RadioLabel = styled(Label)`
  cursor: pointer;
  color: ${({ theme }) => theme.color.brownDark};
  margin-right: .5em;
`;


const RadioOption = styled.div`
	width: 24px;
	height: 24px;
	border: ${({ theme, checked, error }) => checked ? `8px solid ${theme.color.orangeDark}` : error ? `1px solid ${theme.color.redRegular}` : `1px solid ${theme.color.brownRegular}`};
	border-radius: 50%;
	background: 'white';
  margin-right: .25em;

	&:hover {
		border: ${({ theme, checked }) => checked ? `8px solid ${theme.color.orangeDark}` : `1px solid ${theme.color.orangeDark}`};
	}
`

const RadioButtonField = (props) => {
  const {
    label,
    options,
    field,
    form,
    title,
    required,
    row,
  } = props

  const handleClick = (value) => {
    form.setFieldValue(field.name, value)
  }

  return (
    <Container>
      <Label required={required}>
        {label}
        {title && <Tooltip title={title} />}  
      </Label>
      <Container row={row}>
        {options.map((option) => (    
          <RadioLabel key={option.value} onClick={() => handleClick(option.value)}>
            <RadioOption
              {...field}
              value={field.value || ''}
              checked={field.value === option.value || false}
              error={form.touched[field.name] && form.errors[field.name]}
              onClick={() => handleClick(option.value)}
            />
            {option.label}
          </RadioLabel>
        ))}
      </Container>
      <ErrorMessage render={msg => msg !== 'customErrorMessage' && <FormError>{msg}</FormError>} name={field.name} />
    </Container>
  )
}

export default RadioButtonField
