import React from 'react'
import { Field } from 'formik'
import { Col } from 'styled-bootstrap-grid'
import FormGroup from '../components/layout/FormGroup'
import RadioGroupField from '../components/form/RadioGroupField'
import InputField from '../components/form/InputField'
import Hr from '../components/layout/Hr'
import H1 from '../components/typography/H1'

const genderTypes = [
  {
    label: 'Meneer',
    value: 'Man',
  },
  {
    label: 'Mevrouw',
    value: 'Vrouw',
  },
]

const Form = () => (
  <>
    <H1>Jouw gegevens</H1>
    <Hr />
    <FormGroup row>
      <Col lg="6">
        <Field name="gender" component={RadioGroupField} label="Aanhef" options={genderTypes} required />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="initials" component={InputField} label="Voorletter(s)" required />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="prefix" component={InputField} label="Tussenvoegsel" />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="name" component={InputField} label="Achternaam" required />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="phone_number" component={InputField} label="Telefoonnummer" />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="phone_mobile" component={InputField} label="Mobiel" />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="email" component={InputField} label="E-mailadres" disabled />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col lg="6">
        <Field name="street" component={InputField} label="Straatnaam" required />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Col xs="12" sm="4" lg="2">
        <Field name="zipcode" component={InputField} label="Postcode" required />
      </Col>
      <Col xs="6" sm="4" lg="2">
        <Field name="house_number" component={InputField} label="Huisnummer" required />
      </Col>
      <Col xs="6" sm="4" lg="2">
        <Field name="house_number_affix" component={InputField} label="Toevoeging" placeholder="Optioneel" />
      </Col>
    </FormGroup>
  </>
)

export default Form
