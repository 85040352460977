import { AxiosPromise } from 'axios'
import { get, post, put, apiUrl } from '.'

export const initOrder = (encryptedString: any): AxiosPromise<any> =>
  post({
    url: `${apiUrl}/orders/init`,
    data: { query: encryptedString },
  })

export const createOrder = (order: any): AxiosPromise<any> => {
  const formData = new FormData()
  Object.keys(order).forEach((key) => formData.append(key, order[key]))

  return post({
    url: `${apiUrl}/orders/`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const createContract = (data: any, config?: any): AxiosPromise<any> => {
  const formData = new FormData()
  Object.keys(data).forEach((key) => formData.append(key, data[key]))

  return post({
    url: `${apiUrl}/orders/contract`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...config,
  })
}

export const updateOrder = (order: any): AxiosPromise<any> =>
  put({
    url: `${apiUrl}/orders/${order.id}`,
    data: order,
  })

export const downloadOrder = (encryptedString: any): AxiosPromise<any> =>
  get({
    url: `${apiUrl}/orders/download`,
    params: { data: encryptedString },
    responseType: 'blob',
  })
