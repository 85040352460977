import styled from 'styled-components'

const Alert = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 2rem;
  background-color: ${(props) => (props.severity === 'danger' ? '#fdeded' : props.theme.color.brownLight)};
  color: ${(props) => (props.severity === 'danger' ? '#5f2120' : props.theme.color.purpleDark)};
  border-radius: 3px;
  padding: 1rem;
  margin-bottom: 0.5rem;
  padding: 2rem;
`

export const AlertTitle = styled.h4`
  padding: 6px 0;
`

export default Alert
