import styled from 'styled-components'

export default styled.input`
  background: #FFFFFF;
  border: 1px solid ${({ error, status, theme: { color } }) => error ? color.redDark : status ? color.success : color.brownRegular};
  border-radius: 3px;
  height: 45px;
  font-family: ${({ theme }) => theme.font.default};
  color:  ${({ theme }) => theme.color.brownDark};
  padding: 1rem 1.3rem;
  max-width: 100%;

  :focus {
    outline: none;
    border-color: #9A90B8;
  }

  :disabled {
    background-color: #E7E7EF;
    border-color: #E7E7EF;
  }

  ::placeholder {
    color: ${(props) => props.theme.textColor.faded};
  }
`
