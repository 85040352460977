import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { FormikConsumer } from 'formik'
import { Col } from 'styled-bootstrap-grid'
import Hr from '../layout/Hr'
import H1 from '../typography/H1'
import FormGroup from '../layout/FormGroup'
import CheckboxField from './CheckboxField'
import SelectField from './SelectField'
import Alert from '../layout/Alert'
import { getBanks } from '../../api/paymentApi'
import InputField from './InputField'
import Link from '../layout/Link'

const DataHeader = styled.h4`
  color: ${({ theme }) => theme.color.purpleDark};
  margin-bottom: 0.5em;
`

const OverviewBox = styled.div`
  background: ${({ theme }) => `${theme.color.brownLight}`};
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 1em;
`

const DD = styled.dd`
  margin-bottom: .5rem;
`

const BankSelect = ({ payment_status }) => {
  const [banks, setBanks] = useState([])
  useEffect(() => {
    const loadBanks = async () => {
      const banks = await getBanks()
      setBanks(banks.data.map((bank) => ({ value: bank.bic, label: bank.name })))
    }
    loadBanks()
  }, [])

  return (
    <>
      <FormGroup row>
        <Col lg="4">
          <Field name="bank" component={SelectField} label="Selecteer jouw bank" options={banks} />
        </Col>
      </FormGroup>
      {payment_status && <Alert>Betaling niet geslaagd.</Alert>}
    </>
  )
}

const CouponField = ({ status }) => (
  <FormGroup row>
    <Col lg="2">
      <Field name="coupon" component={InputField} label="Couponcode" status={status} />
    </Col>
  </FormGroup>
)

const PaymentMethods = ({ payment_status, coupon, errors }) => {
  let status = undefined
  if (coupon && !errors['coupon']) {
    status = {coupon: 'valid'}
  }
  return (
    <>
      <BankSelect payment_status={payment_status} />
      <CouponField status={status} />
    </>
  )
}

const Overview = ({ pay }) => (
  <div>
    <H1>Overzicht</H1>
    <Hr />
    <p>Controleer onderstaande gegevens, accepteer de voorwaarden en start de {pay ? 'koopcontractcontrole' : 'scan'}.</p>
    <FormikConsumer>
      {({
        values: {
          modelContract,
          oldbuild,
          buyer_type,
          filename,
          initials,
          prefix,
          name,
          phone_number,
          phone_mobile,
          email,
          street,
          zipcode,
          house_number,
          house_number_affix,
          payment_status,
          coupon,
        },
        errors, 
      }) => (
        <>
          <OverviewBox>
            <H1>Samenvatting</H1>
            <Hr />
            <DataHeader>Jouw gegevens</DataHeader>
            <dl>
              <DD>
                Naam: {initials} {prefix} {name}
              </DD>
              <DD>Emailadres: {email}</DD>
              {pay && phone_number && <DD>Telefoonnummer: {phone_number}</DD>}
              {pay && phone_mobile && <DD>Telefoonnummer: {phone_mobile}</DD>}
              {pay && street && <DD> Adres: {street} {house_number} {house_number_affix}</DD>}
              {pay && zipcode && <DD> Postcode: {zipcode}</DD>}
              <DD>Modelkoopovereenkomst: {modelContract ? 'Ja' : 'Nee'}</DD>
              <DD>Bestaande bouw: {oldbuild ? 'Ja' : 'Nee'}</DD>
              {buyer_type !== 'anonymous' && <DD>Eerste huis: {buyer_type === 'STARTER' ? 'Ja' : 'Nee'}</DD>}
              <DD>Koopovereenkomst: {filename}</DD>
            </dl>
          </OverviewBox>
          {pay && <PaymentMethods payment_status={payment_status} coupon={coupon} errors={errors} />}
        </>
      )}
    </FormikConsumer>
    <FormGroup>
      <Field
        name="conditions"
        component={CheckboxField}
        label={
          <span>
            Ik heb kennisgenomen van het <Link href="https://www.eigenhuis.nl/privacystatement#/">privacy statement</Link> en de <Link href="https://www.eigenhuis.nl/docs/default-source/downloads/kopen/av-koopcontract-scan-koopcontract-controle-(1-nov-2020).pdf?sfvrsn=ce33b396_2">algemene voorwaarden</Link> van Vereniging Eigen Huis en ga
            daarmee akkoord.*
          </span>
        }
      />
    </FormGroup>
  </div>
)

export default Overview
