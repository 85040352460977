import React from 'react'
import styled from 'styled-components'
import check from '../../assets/check-24px.svg'

const Bar = styled.ol`
  list-style: none;
  padding: none;
  margin: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  max-width: 400px;

  ${({ current, length, theme }) =>
    current === 1
      ? `
    &::after {
      position: absolute;
      content: " ";
      height: 3px;
      left: ${50/length}%;
      right: ${50/length}%;
      top: 14px;
      background-color: ${theme.color.purpleLight};
      z-index: 2;
    }`
      : current === 2
      ? `
    &::after {
      position: absolute;
      content: " ";
      height: 3px;
      left: ${75/length*current}%;
      right: ${50/length}%;
      top: 14px;
      background-color: ${theme.color.purpleLight};
      z-index: 2;
    }
    &::before {
      position: absolute;
      content: " ";
      height: 3px;
      left: ${50/length}%;
      right: ${75/length*current}%;
      top: 14px;
      background-color: ${theme.color.orangeDark};
      z-index: 2;
    }`
      : current === 3
      ? `
    &::before {
      position: absolute;
      content: " ";
      height: 3px;
      left: ${length === 3 ? 50/length : 50/length*current}%;
      right: ${50/length}%;
      top: 14px;
      background-color: ${theme.color.purpleLight};
      z-index: 2;
    }
    &::after {
      position: absolute;
      content: " ";
      height: 3px;
      left: ${50/length}%;
      right: ${length === 3 ? 50/length : 50/length*current}%;
      top: 14px;
      background-color: ${theme.color.orangeDark};
      z-index: 2;
    }`
      : `&::after {
        position: absolute;
        content: " ";
        height: 3px;
        left: ${50/length}%;
        right: ${50/length}%;
        top: 14px;
        background-color: ${theme.color.orangeDark};
        z-index: 2;
    }`}
`

const Number = styled.li`
  list-style: none;
  padding: none;
  margin: none;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  text-align: center;
  ${({ past }) => 
    past &&
    `:hover {
      cursor: pointer;
    }`
  }
`

const Label = styled.li`
  list-style: none;
  padding: none;
  margin: none;
  font-size: 12px;
`

const Item = styled.div`
  list-style: none;
  padding: none;
  margin: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  width: 100%;
  ${Number} {
    background-color: ${({ past, theme }) => (past ? theme.color.orangeDark : theme.color.purpleLight)};
    color: ${({ active, theme }) => (active ? theme.color.orangeDark : 'white')};
    border: ${({ active, theme }) => (active ? `3px solid ${theme.color.orangeDark}` : null)};
    background: ${({ past, active, theme }) => (past ? `${theme.color.orangeDark} url(${check}) no-repeat center` : active ? 'white' : null)};
    font-size: ${({ past }) => (past ? '0px' : true)};
    line-height: ${({ active }) => (active ? '27px' : '30px')};
  }
  ${Label} {
    color: ${({ past, active, theme }) => (active || past ? theme.color.orangeDark : theme.color.purpleLight)};
  }
`

const ProgressBar = ({ items, current, locations, history, setErrors }) => (
  <Bar current={current} length={items.length}>
    {items.map(({ idx, name }) => (
      <Item key={idx} active={current === idx} past={current > idx}>
        <Number past={current > idx} onClick={() => {
          if (current > idx) {
            setErrors({})
            history.push(locations[idx - 1])}
          }}>{idx}</Number>
        <Label>{name}</Label>
      </Item>
    ))}
  </Bar>
)

export default ProgressBar
