import React from 'react'
import styled from 'styled-components'
import Label from './Label'
import check from '../../assets/check-24px.svg'
import FormError from './FormError'
import { ErrorMessage } from 'formik'

const Container = styled.div`
  margin-bottom: .8rem;
`

const CheckboxLabel = styled(Label)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.color.brownDark};
  margin-bottom: 0;
`

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
	width: 24px;
	height: 24px;
	border: 1px solid ${({ theme, error }) => error ? theme.color.redRegular : theme.color.brownRegular};
  border-radius: 3px;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background: url(${() => check}) no-repeat center;
  }
`

const CheckboxContainer = styled.div`
  display: block;
  position: relative;
  padding-left: 35px;
  height: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* On mouse-over, add a grey background color */
  &:hover input ~ span {
    background: ${({ theme }) => theme.color.orangeDark};
	  border: 1px solid ${({ theme }) => theme.color.brownRegular};
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ span {
    background: ${({ theme }) => theme.color.orangeDark};
    border: ${({ theme }) => `1px solid ${theme.color.orangeDark}`};
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ span:after {
    display: block;
  }
`

const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const CheckBoxField = ({ id, inline, label, field, form, ...restProps }) => (
  <Container>
    <CheckboxLabel>
      <CheckboxContainer>
        <CheckBox
          {...field}
          value={field.value || ''}
          type="checkbox"
          checked={field.value || false}
          id={id}
          onClick={() => form.setFieldValue(field.name, !field.value)}
        />
        <Checkmark error={form.errors[field.name] ? true : false}/>
      </CheckboxContainer>
      {label}
    </CheckboxLabel>
    <ErrorMessage component={FormError} name={field.name} />
  </Container>
)

export default CheckBoxField
