import React from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik'
import Button from './Button'
import ButtonSecondary from './ButtonSecondary'
import ProgressBar from './ProgressBar'
import { initOrder } from '../../api/orderApi'
import ResizeListener from '../ResizeListener'
import { DEVICE_SM_DOWN } from '../../utils/deviceUtils'

const Container = styled.div`
  margin-top: 2rem;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;

  > * {
    margin-right: 1rem;
  }

  > *:last-child {
    margin-right: 0;
  }

  @media ${DEVICE_SM_DOWN} {
    > * {
      width: 50%;
    }
  }
`

const getQueryStringValue = (search, key) => {
  return decodeURIComponent(search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'))
}

class Wizard extends React.Component {
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      values: props.initialValues,
      isLoading: true,
      error: '',
    }
  }

  componentDidMount = () => {
    const { values } = this.state
    const { history } = this.props
    const encryptedString = getQueryStringValue(this.props.location.search, 'data')

    if (encryptedString) {
      initOrder(encryptedString)
        .then((response) => {
          this.setState({ values: { ...values, ...response.data }, isLoading: false })
          if (response.data.id && response.data.payment_status === 'SUCCESS') {
            history.push('/betaald/bedankt')
          } else if (response.data.id && response.data.payment_status) {
            history.push('/betaald/confirm')
          }
        })
        .catch((e) => this.setState({ isLoading: false, error: e.message }))
    }
  }

  next = (values) => {
    const { location, locations, history } = this.props
    this.setState(() => ({ values }))

    // withRouter provides current location and history.push() to go to next page
    const nextPath = locations.indexOf(location.pathname) + 1
    history.push(locations[nextPath])
  }

  previous = () => {
    const { location, locations, history } = this.props

    const prevPath = locations.indexOf(location.pathname) - 1
    history.push(locations[prevPath])
  }

  validate = (values) => {
    const { location, locations, children } = this.props

    const page = locations.indexOf(location.pathname)
    const activePage = React.Children.toArray(children)[page]
    let errors = activePage.props.validate ? activePage.props.validate(values) : {}
    return errors
  }

  handleSubmit = (values, bag) => {
    const { children, onSubmit, location, locations, history } = this.props
    const page = locations.indexOf(location.pathname)
    const isSubmitPage = page === React.Children.count(children) - 2
    if (isSubmitPage) {
      onSubmit(values, bag, history)
    }
    if (location.pathname !== '/betaald/confirm') {
      bag.setTouched({})
      bag.setSubmitting(false)
      this.next(values)
    }
  }

  render() {
    const { children, location, locations, history, barItems, validationSchema } = this.props
    const { values, isLoading, error } = this.state
    // current page is determined by matching path in locations
    const page = locations.indexOf(location.pathname)
    const activePage = React.Children.toArray(children)[page]
    const isSubmitPage = page === React.Children.count(children) - 2
    const isLastPage = page === React.Children.count(children) - 1

    return (
      <ResizeListener>
        {isLoading && <div>Een moment geduld aub...</div>}
        {!isLoading && error && <div>Er ging wat mis. Probeer het nog eens.</div>}
        {!isLoading && !error && (
          <Formik initialValues={values} enableReinitialize={true} validationSchema={validationSchema[page]} onSubmit={this.handleSubmit}>
            {({ handleSubmit, isSubmitting, submitCount, errors, setErrors }) => (
              <form onSubmit={handleSubmit}>
                <ProgressBar items={barItems} current={page + 1} locations={locations} history={history} setErrors={setErrors} />
                <Container>
                  {activePage}
                  {!isLastPage && (
                    <ButtonContainer>
                      {page > 0 && (
                        <ButtonSecondary
                          type="button"
                          onClick={() => {
                            setErrors({})
                            this.previous()
                          }}>
                          Stap terug
                        </ButtonSecondary>
                      )}
                      {!isSubmitPage && (
                        <Button type="submit" disabled={Object.keys(errors).length > 0 ? true : false}>
                          Verder
                        </Button>
                      )}
                      {isSubmitPage && (
                        <Button type="submit" disabled={isSubmitting || (submitCount > 0 && Object.keys(errors).length > 0 ? true : false)}>
                          {isSubmitting && 'Een moment aub'}
                          {!isSubmitting && (window.location.href.indexOf('betaald') !== -1 ? 'Afrekenen' : 'Start koopovereenkomst scan')}
                        </Button>
                      )}
                    </ButtonContainer>
                  )}
                </Container>
              </form>
            )}
          </Formik>
        )}
      </ResizeListener>
    )
  }
}

export default withRouter(Wizard)
