import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { createContract } from '../api/orderApi'
import { newCancellationToken, isCancellationError } from '../api'
import H4 from '../components/typography/H4'
import Alert, { AlertTitle } from '../components/layout/Alert'
import LoadingIndicator from '../components/LoadingIndicator'
import TextLink from '../components/form/TextLink'
import infoSvg from '../assets/info_outline-24px.svg'
import errorSvg from '../assets/error_outline-24px.svg'

const Bullets = styled.ul`
  padding-left: 2rem;
`

const Box = styled.div`
  padding-top: 1rem;
`

const ValidateContract = () => {
  const [state, setState] = useState({
    loading: false,
    success: false,
    error: false,
  })
  const { values, setErrors, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values.contractValid) {
      setState({
        loading: false,
        success: true,
        error: false,
      })
      return
    }

    const source = newCancellationToken()

    if (values.file) {
      setState((prev) => ({ ...prev, loading: true }))
      setErrors({ file: 'not-validated' })

      createContract(
        {
          file: values.file,
          contract: values.contract,
        },
        { cancelToken: source.token }
      )
        .then((response) => {
          if (response.data.success) {
            setState((prev) => ({ ...prev, loading: false, success: true }))
            setErrors({})
            setFieldValue('contractValid', response.data.success)
          } else {
            setState((prev) => ({ ...prev, loading: false, error: true }))
            setErrors({ file: 'invalid' })
          }
        })
        .catch((e) => {
          if (!isCancellationError(e)) {
            setState((prev) => ({ ...prev, loading: false, error: true }))
            setErrors({ file: 'invalid' })
          }
        })
    } else {
      // reset state
      setState({
        loading: false,
        success: false,
        error: false,
      })
    }

    return () => {
      source.cancel()
    }
  }, [values.file, values.contract, values.contractValid, setErrors, setFieldValue])

  return (
    <Box>
      {state.loading && (
        <>
          <H4>Je koopcontract wordt geüpload</H4>
          <span>Een momentje geduld, we valideren jouw contract. Dit duurt ongeveer 30 seconden.</span>
          <LoadingIndicator />
        </>
      )}
      {state.success && (
        <Alert>
          <img style={{ marginRight: '1.5rem' }} src={infoSvg} alt="" />
          <div>
            <AlertTitle>Je koopcontract is geüpload</AlertTitle>
            <span>We hebben je koopcontract ontvangen en deze kan digitaal door ons worden gecontroleerd.</span>
          </div>
        </Alert>
      )}
      {state.error && (
        <Alert severity="danger">
          <img style={{ marginRight: '1.5rem' }} src={errorSvg} alt="" />
          <div>
            <AlertTitle>Uploaden koopcontract mislukt</AlertTitle>
            <span>Het uploaden van je koopcontract is niet goed gegaan, omdat er een afwijking zit in het contract. Dit kan komen door:</span>
            <Bullets>
              <li>Het is niet de standaard model koopovereenkomst</li>
              <li>Er zit een voorblad bij of er staan logo's in het document</li>
            </Bullets>
            <span>Mocht je hier nog vragen over hebben, neem dan contact op met het secretariaat: 033-4507687.</span>
            <Box>
              <AlertTitle>Contractbeoordeling door een jurist</AlertTitle>
              <span>
                Wil je het contract alsnog laten controleren, boek dan een koopcontractbeoordeling door een van onze juristen. Tijdens een telefonisch gesprek
                worden de bevindingen besproken en krijg je tips voor onderhandeling en advies voor contractwijzigingen.
                <TextLink href="https://www.eigenhuis.nl/diensten/kopen-diensten/koopcontract-bestaande-bouw" target="blank">
                  Boek beoordeling
                </TextLink>
              </span>
            </Box>
          </div>
        </Alert>
      )}
    </Box>
  )
}

export default ValidateContract
