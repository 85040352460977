import React from 'react'
import { Field, FormikConsumer } from 'formik'
import FormGroup from '../components/layout/FormGroup'
import RadioGroupField from '../components/form/RadioGroupField'
import Hr from '../components/layout/Hr'
import H1 from '../components/typography/H1'
import Alert from '../components/layout/Alert'
import Link from '../components/layout/Link'
import info_svg from '../assets/info_outline-24px.svg'

const buyerTypes = [
  {
    label: 'Ik koop mijn eerste woning',
    value: 'STARTER',
  },
  {
    label: 'Ik heb al een woning',
    value: 'NO_STARTER',
  },
  {
    label: 'Zeg ik liever niet',
    value: 'anonymous'
  }
]

const boolChoices = [
  {
    label: 'Ja',
    value: true
  },
  {
    label: 'Nee',
    value: false
  }
]

const SanityCheck = ({ pay }) => (
  <>
    <H1>Start {pay ? 'digitale koopcontract controle' : 'koopovereenkomst scan'} bestaande bouw</H1>
    <Hr />
    <p>
      Beantwoord de volgende vragen zodat wij kunnen nagaan of de scan jou kan helpen.
    </p>
    <FormGroup>
      <Field
        name="buyer_type"
        label="Selecteer de situatie die voor jou van toepassing is"
        component={RadioGroupField}
        options={buyerTypes}
        required
      />
    </FormGroup>
    <FormGroup>
      <Field
        name="modelContract"
        label="Mijn koopcontract is opgesteld aan de hand van een modelkoopovereenkomst"
        title="Dit vind je terug in de eerste alinea van het koopcontract. Hier staat dan benoemd:
        ‘Model koopovereenkomst voor een bestaande eengezinswoning’ of ‘Model koopovereenkomst voor een bestaand appartementsrecht’."
        component={RadioGroupField}
        options={boolChoices}
        required
        row
      />
      <Field
        name="oldbuild"
        label="Ik koop een bestaand huis"
        component={RadioGroupField}
        options={boolChoices}
        required
        row
      />
    </FormGroup>
    <FormikConsumer>
      {({ errors, values }) =>
        <>
          {errors.modelContract && values.modelContract !== '' && (
            <Alert>
              <img style={{ marginRight: '1.5rem' }} src={info_svg} alt="" />
              <span>
              Een koopcontract dat niet is opgesteld aan de hand van een modelovereenkomst kan niet gecontroleerd worden met de digitale controle. 
              Uiteraard kunnen onze juristen je koopcontract wel beoordelen. Dit geeft jou direct de mogelijkheid om al je vragen te stellen.
              <Link href="http://eigenhu.is/koop-aannemingsovereenkomst-beoordeling"> Vraag een beoordeling aan</Link>.
              </span>
            </Alert>
          )}
          {errors.oldbuild && values.oldbuild !== '' && (
            <Alert>
              <img style={{ marginRight: '1.5rem' }} src={info_svg} alt="" />
              <span>
                Koop je een nieuwbouwwoning? Dan teken je een koop-/aanneemovereenkomst. Het model koopcontract is alleen van toepassing bij de koop van een bestaand huis. 
                Een koop-aannemingsovereenkomst is vaak complex. Daarom is het altijd verstandig om een jurist deze te laten controleren. 
                Bekijk <Link href="http://eigenhu.is/koop-aannemingsovereenkomst-beoordeling">hier</Link> de beoordeling van een koop-aannemingsovereenkomst door een van onze juristen.
              </span>
            </Alert>
          )}
        </>
      }
    </FormikConsumer>
  </>
)

export default SanityCheck
